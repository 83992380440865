.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(400px, 100vw);
  border-radius: 4px;
  background-color: white;
  padding: 20px 12px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background-color: #dad9e0
}

.appName {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 4px 0;
}

.welcome {
  margin-bottom: 32px;
}
